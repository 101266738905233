import React, {useContext} from 'react';
import {Box, Container, Link as MuiLink, Stack, Typography, useMediaQuery} from '@mui/material';
import settingsContext from 'context/settingsContext';
import {THEMES} from 'constants/themes';
import LogoWhite from 'assets/images/logo-black.png';
import LogoBlack from 'assets/images/logo-white.png';
import AuthButtons from '../AuthButtons';
import {AuthContext} from 'context/authContext';
import {Link, useNavigate} from 'react-router-dom';
import {NETWORK} from 'constants/network';
import {saveAs} from 'file-saver';
import moment from 'moment';
import NavList from '../NavList';
import {ROUTES} from 'constants/routes';

const ADDRESSES = [
    {title: 'Адрес', descr: 'Лаборатория медиа\nМосква, ул.Гиляровского, д.50'},
    {title: '', descr: 'info@medialab.team\n+7 999 875-01-32'}
];

const ADDRESSES_MOBILE = ['+7 (999) 875-01-32', 'info@medialab.team', 'Москва, ул.Гиляровского, д.50'];

const Footer = () => {
    const navigate = useNavigate();
    const {settings} = useContext(settingsContext);
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const {data} = useContext(AuthContext);
    return (
        <Box sx={{textTransform: 'uppercase', borderTop: (theme) => '1px solid' + theme.palette.systemShift['400']}}>
            {isLg ? (
                <Container>
                    <Stack
                        flexGrow={1}
                        direction={'column'}
                        sx={{
                            my: 6,
                            width: '100%',
                            '& .MuiTypography-root': {paddingTop: 0, color: 'system.600'}
                        }}>
                        <Stack direction={'row'} spacing={2} justifyContent={'space-between'}  flexGrow={1}>
                            <Stack flexGrow={1}>
                                <Stack direction={'row'} justifyContent={'space-between'} mb={7}>
                                    <Stack flexGrow={1}>
                                        <Box
                                            component={Link}
                                            to={ROUTES.LANDING}
                                            sx={{mb: 3}}
                                            onClick={() => window.scrollTo(0, 0)}>
                                            <img
                                                src={settings.theme === THEMES.DARK ? LogoBlack : LogoWhite}
                                                height={50}
                                                alt={'logo'}
                                            />
                                        </Box>
                                        <Box sx={{'& :first-of-type': {ml: 0}}}>
                                            {NETWORK.map((item, index) => (
                                                <MuiLink
                                                    href={item.link}
                                                    key={index}
                                                    sx={{mx: 1, '& svg': {height: '12px', color: 'systemShift.900'}}}>
                                                    {item.icon}
                                                </MuiLink>
                                            ))}
                                        </Box>
                                    </Stack>
                                    <Stack alignItems={'flex-start'}
                                           flexGrow={1}
                                           sx={{my: 0, '& .MuiListItem-root': {paddingTop: 0}}}>
                                        <NavList/>
                                    </Stack>
                                </Stack>

                                <Typography variant={'p4'}>(с) 2021-{moment().format('YYYY')} АНО «Лаборатория медиа»</Typography>
                            </Stack>
                            <Stack flexGrow={1}>
                                <Stack direction={'row'} justifyContent={'space-between'} spacing={2} mb={11}  flexGrow={1}>
                                    <Stack justifyContent={'space-between'}  flexGrow={1}>
                                        {ADDRESSES.map((item, index) => (
                                            <Box key={index} sx={{mb: 2}}>
                                                <Typography noWrap fontWeight={500}>
                                                    {item.title}
                                                </Typography>
                                                <Typography fontWeight={500} noWrap sx={{whiteSpace: 'pre-wrap'}}>
                                                    {item.descr}
                                                </Typography>
                                            </Box>
                                        ))}
                                    </Stack>
                                    <Stack flexGrow={1}>
                                        <Typography
                                            noWrap
                                            fontWeight={500}
                                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                                            onClick={() =>
                                                saveAs('https://medialab.team/docs/privacy-policy.pdf', 'privacy-policy.pdf')
                                            }>
                                            Политика
                                            <br/>
                                            конфиденциальности
                                        </Typography>
                                        <Typography
                                            noWrap
                                            fontWeight={500}
                                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer', mt: 1}}
                                            onClick={() => saveAs('https://medialab.team/docs/oferta.pdf', 'oferta.pdf')}>
                                            Условия
                                            <br/>
                                            публичной оферты
                                        </Typography>
                                        <Typography
                                            noWrap
                                            fontWeight={500}
                                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer', mt: 1}}
                                            onClick={() => window.open('https://disk.yandex.ru/d/QGhCrXGzp8xkOw')}>
                                            Отчеты
                                        </Typography>
                                        <Typography
                                            noWrap
                                            fontWeight={500}
                                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer', mt: 1}}
                                            onClick={() => navigate(ROUTES.ORGANIZATION_INFORMATION)}>
                                            Сведения об образовательной<br/>организации
                                        </Typography>
                                    </Stack>
                                    {data?.me ? (
                                        <Stack spacing={1}  flexGrow={1}>
                                            <Typography
                                                fontWeight={500}
                                                color={(theme) =>
                                                    location.pathname === ROUTES.PROFILE
                                                        ? theme.palette.systemShift[900] + ' !important'
                                                        : theme.palette.systemShift[600]
                                                }
                                                onClick={() => navigate(ROUTES.PROFILE)}
                                                sx={{cursor: 'pointer'}}>
                                                Личный кабинет
                                            </Typography>
                                            <Typography
                                                fontWeight={500}
                                                color={(theme) => theme.palette.systemShift[900] + '!important'}
                                                onClick={() => navigate(ROUTES.TRAJECTORY)}
                                                sx={{
                                                    color: (theme) =>
                                                        location.pathname === ROUTES.TRAJECTORY
                                                            ? theme.palette.systemShift[900] + ' !important'
                                                            : theme.palette.system[600],
                                                    cursor: 'pointer'
                                                }}>
                                                Мои курсы
                                            </Typography>
                                        </Stack>
                                    ) : (
                                        <AuthButtons sx={{flexGrow: 1}}/>
                                    )}
                                </Stack>
                                <Typography sx={{maxWidth: 900}} variant={'p4'}>
                                    Образовательные услуги оказываются Автономной некоммерческой организацией
                                    «Лаборатория&nbsp;медиа» на&nbsp;основании Лицензии №&nbsp;Л035-01298-77/00665244
                                    от&nbsp;20&nbsp;июля&nbsp;2023.<br/> Образовательные
                                    услуги оказываются в соответствии с Федеральным законом от&nbsp;04.05.2011&nbsp;№
                                    99-ФЗ
                                    «О&nbsp;лицензировании&nbsp;отдельных&nbsp;видов&nbsp;деятельности».
                                </Typography>

                            </Stack>
                        </Stack>
                    </Stack>
                </Container>
            ) : (
                <Stack
                    direction={'column'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    sx={{mt: 6, height: 'auto', '& *': {color: 'system.600'}}}>
                    <Box component={Link} to={ROUTES.LANDING} onClick={() => window.scrollTo(0, 0)}>
                        <img src={settings.theme === THEMES.DARK ? LogoBlack : LogoWhite} height={35} alt={'logo'}/>
                    </Box>
                    <Stack alignItems={'center'} spacing={1} sx={{my: 3, '& .MuiListItem-root': {paddingTop: 0}}}>
                        <NavList/>
                    </Stack>
                    <Stack
                        direction={'row'}
                        justifyContent={'center'}
                        sx={{
                            width: '100%',
                            py: 3,
                            borderTop: (theme) => '1px solid' + theme.palette.systemShift['400'],
                            borderBottom: (theme) => '1px solid' + theme.palette.systemShift['400']
                        }}>
                        {NETWORK.map((item, index) => (
                            <MuiLink href={item.link} key={index} sx={{mx: 4, '& svg': {height: '15px'}}}>
                                {item.icon}
                            </MuiLink>
                        ))}
                    </Stack>
                    <Stack alignItems={'center'} sx={{my: 4}} spacing={3}>
                        {ADDRESSES_MOBILE.map((item, index) => (
                            <Typography key={index} noWrap>
                                {item}
                            </Typography>
                        ))}
                        <Typography
                            noWrap
                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                            onClick={() =>
                                window.open('https://medialab.team/docs/privacy-policy.pdf', '_blank')
                            }>
                            Политика конфиденциальности
                        </Typography>
                        <Typography
                            noWrap
                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                            onClick={() => saveAs('https://medialab.team/docs/oferta.pdf', 'oferta.pdf')}>
                            Условия публичной оферты
                        </Typography>
                        <Typography
                            noWrap
                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                            onClick={() => window.open('https://disk.yandex.ru/d/QGhCrXGzp8xkOw')}>
                            Отчеты
                        </Typography>
                        <Typography
                            variant={'p4'}
                            noWrap
                            sx={{whiteSpace: 'pre-wrap', cursor: 'pointer'}}
                            onClick={() => navigate(ROUTES.ORGANIZATION_INFORMATION)}>
                            Сведения об образовательной организации
                        </Typography>
                        <Typography noWrap>(с) 2021-{moment().format('YYYY')} АНО «Лаборатория медиа»</Typography>
                        <Typography mt={9} px={4} textAlign={'center'} variant={'p4'}>
                            Образовательные услуги оказываются Автономной&nbsp;некоммерческой&nbsp;организацией
                            «Лаборатория&nbsp;медиа» на основании Лицензии №&nbsp;Л035-01298-77/00665244
                            от&nbsp;20&nbsp;июля&nbsp;2023. Образовательные
                            услуги оказываются в соответствии с Федеральным законом от&nbsp;04.05.2011 №&nbsp;99-ФЗ «О
                            лицензировании отдельных видов деятельности».
                        </Typography>
                    </Stack>
                </Stack>
            )}
        </Box>
    );
};

export default Footer;
