import React, {useState} from 'react';
import {Button, CardMedia, Grid, Stack, styled, Tooltip, Typography, useMediaQuery} from '@mui/material';
import {useNavigate} from 'react-router-dom';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis/lib/index.modern.mjs';
import responsiveHOC from 'react-lines-ellipsis/lib/responsiveHOC';
import {ROUTES} from 'constants/routes';

const ResponsiveEllipsis = responsiveHOC()(LinesEllipsis);

const MobileBlogCard = ({post}) => {
    const navigate = useNavigate();
    const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const [clampedTitle, setClampedTitle] = useState('');
    const [clampedDescription, setClampedDescription] = useState('');

    return (
        <StyledCard onClick={() => navigate(ROUTES.BLOG + '/' + post?.id)}>
            <Stack spacing={2}>
                <Tooltip title={clampedTitle ? post?.name : ''}>
                    <Typography variant={'h5'} gutterBottom mt={-0.75}>
                        <ResponsiveEllipsis
                            text={post?.name}
                            maxLine={post?.coverFile ? (isLg ? '3' : '1') : isLg ? '5' : '3'}
                            trimRight
                            ellipsis='...'
                            onReflow={(rleState) => setClampedTitle(rleState.clamped)}
                            basedOn='letters'
                        />
                    </Typography>
                </Tooltip>
                {post?.coverFile && (
                    <Stack direction={'row'} justifyContent={'center'}>
                        <CardMedia
                            component='img'
                            image={post?.coverFile?.url}
                            alt={post?.coverFile?.name || 'image'}
                            sx={{width: 130}}
                        />
                    </Stack>
                )}
                <Tooltip title={clampedDescription ? post?.description : ''}>
                    <Typography color={'systemShift.700'}>
                        <ResponsiveEllipsis
                            text={post?.description}
                            maxLine={post?.coverFile ? (isLg ? '7' : '4') : isLg ? '14' : '10'}
                            trimRight
                            onReflow={(rleState) => setClampedDescription(rleState.clamped)}
                            ellipsis='...'
                            basedOn='letters'
                        />
                    </Typography>
                </Tooltip>
            </Stack>
            <Stack spacing={2}>
                <Grid
                    container
                    direction={'row'}
                    width={'75%'}
                    spacing={1}
                    wrap={'wrap'}
                    height={43}
                    sx={{overflow: 'hidden'}}>
                    {post?.blogPostInterests?.map((dir) => (
                        <Grid item key={dir?.interest?.id}>
                            <Button
                                size={'small'}
                                variant={'outlined'}
                                sx={{px: 1.25, py: 1, width: 'auto !important', flexShrink: 0}}>
                                {dir?.interest?.name}
                            </Button>
                        </Grid>
                    ))}
                </Grid>

                {post?.publicationDate && (
                    <Typography color={'primary'}>
                        {moment(post?.editedAt).isAfter(moment(post?.publicationDate))
                            ? moment(post?.editedAt)?.format('DD.MM.YYYY')
                            : moment(post?.publicationDate)?.format('DD.MM.YYYY')}
                    </Typography>
                )}
            </Stack>
        </StyledCard>
    );
};

const StyledCard = styled(Stack)(({theme}) => ({
    border: '3px solid' + theme.palette.primary.medium,
    borderRadius: '25px',
    margin: 'auto',
    padding: '30px 20px',
    height: 430,
    maxWidth: 315,
    justifyContent: 'space-between',
    cursor: 'pointer'
}));

export default MobileBlogCard;
