import {gql} from '@apollo/client';

export const GET_BLOG_POSTS = gql`
    query blogPosts($page: Float!, $limit: Float!, $filterInput: BlogPostCompositeFilter) {
        blogPosts(page: $page, limit: $limit, filterInput: $filterInput) {
            data {
                id
                name
                description
                authors {
                    id
                    user {
                        id
                        firstName
                        lastName
                        middleName
                        fullName
                        numericId
                    }
                }
                isHidden
                publicationDate
                editedAt
                createdAt
                type
                coverFile {
                    id
                    key
                    name
                    url
                }
                blogPostInterests {
                    id
                    interest {
                        id
                        name
                    }
                }
            }
            totalCount
            hasPreviousPage
            hasNextPage
        }
    }
`;
