import React, {useContext, useEffect, useRef} from 'react';
import {Box, Link} from '@mui/material';
import {useQuery} from '@apollo/client';
import {GET_BANNER} from 'graphql/landing/getBanner';
import {useLocation} from 'react-router-dom';
import {ROUTES} from 'constants/routes';
import {AuthContext} from "context/authContext";

const Banner = () => {
    const {data} = useQuery(GET_BANNER);
    const {captureGoal} = useContext(AuthContext);
    const banner = useRef();
    const {pathname} = useLocation();

    useEffect(() => {
        const isChats = pathname.includes(ROUTES.CHATS);
        const isEvents = pathname.includes(ROUTES.EVENTS);
        const scrollTop = banner.current?.offsetHeight;
        !!data?.banner && (isChats || isEvents) ? window.scrollTo(0, scrollTop) : window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            {data?.banner && (
                <Box
                    onClick={() => captureGoal('clkBanner', {bannerLink: data?.banner?.link}, false)}
                    ref={banner}
                    id={'im-here'}
                    className={'im-here'}
                    component={Link}
                    href={data?.banner?.link}
                    sx={{
                        backgroundColor: data?.banner?.color,
                        width: '100%',
                        maxHeight: 200,
                        zIndex: 99,
                        display: 'flex',
                        justifyContent: 'center'
                    }}>
                    <img src={data?.banner?.file?.url} style={{maxWidth: '100%', width: '100%'}} />
                </Box>
            )}
        </>
    );
};

export default Banner;
