import {Container, Link, styled, Typography} from '@mui/material';

const OrganizationInformation = () => {
    return (
        <Container sx={{lineHeight: 2,'& p': {lineHeight: 1.7}}}>
            <Typography variant={'h2'} textAlign={'center'}>Сведения об образовательной организации</Typography>
            <Typography variant={'h4'} mt={4} mb={1}>Основные сведения</Typography>
            <Typography>
                <i>Наименование образовательной организации:</i> Автономная некоммерческая организация «Лаборатория медиа», АНО
                «Лаборатория медиа».
            </Typography>
            <Typography>
                <i>Режим и график работы образовательной организации:</i> пн - пт 9.00 — 18.00
            </Typography>
            <Typography>
                <i>Контактный телефон образовательной организации:</i> +7 999 875-01-32
            </Typography>
            <Typography>
                <i>Адрес электронной почты образовательной организации:</i> info@medialab.team
            </Typography>
            <Typography>
                <i>Адрес места осуществления образовательной деятельности:</i> 129110, г.Москва, вн.тер.г. Муниципальный
                округ Мещанский, ул Гиляровского, д.50, офис XXVIII, ком. 2
            </Typography>

            <Typography variant={'h4'} mt={4} mb={1}>Образование</Typography>
            <Typography>
                <i>Наименование образовательной программы:</i> Дополнительная общеобразовательная программа –
                дополнительная общеразвивающая программа: «Медиацентр с нуля»
            </Typography>
            <Typography>
                <i>Форма обучения:</i> исключительно дистанционная
            </Typography>
            <Typography>
                <i>Нормативный срок обучения:</i> срок обучения будет представлен позже
            </Typography>
            <Typography>
                <i>Язык, на котором осуществляется обучение:</i> русский
            </Typography>
            <Typography>
                <i> Учебные предметы, курсы, дисциплины (модули), предусмотренные образовательной программой:</i>
            </Typography>
            <StyledListItem>Модуль 1. Общий блок</StyledListItem>
            <StyledListItem>Модуль 2. За кадром</StyledListItem>
            <StyledListItem>Модуль 3. Монтаж в Adobe Premiere Pro</StyledListItem>
            <StyledListItem>Модуль 4. Редактор</StyledListItem>
            <StyledListItem>Модуль 5. Копирайтер</StyledListItem>
            <StyledListItem>Модуль 6. В кадре</StyledListItem>
            <StyledListItem>Модуль 7. SMM-специалист</StyledListItem>
            <StyledListItem>Модуль 8. Дизайнер</StyledListItem>
            <StyledListItem>Модуль 9. Дизайнер в Adobe: практический блок</StyledListItem>
            <StyledListItem>Модуль 10. Лендинг на TILDA: практический блок</StyledListItem>
            <StyledListItem>Модуль 11. Моушн-дизайнер</StyledListItem>
            <StyledListItem>Модуль 12. Продюсер</StyledListItem>
            <StyledListItem>Модуль 13. Продюсер: специфика работы по проектам</StyledListItem>
            <StyledListItem>Модуль 14. Саунд-дизайнер</StyledListItem>

            <Typography variant={'h4'} mt={4} mb={1}>Документы</Typography>
            <Link href={'https://disk.yandex.ru/d/N6f1XDNAX1JRzg'} color={'primary'} target={'_blank'}>Программа обучения</Link><br/>
            <Link href={'https://disk.yandex.ru/d/Jj7UwDZaEP6NIg'} color={'primary'} target={'_blank'}>Учебный план</Link><br/>
            <Link href={'https://disk.yandex.ru/d/oxPdV3AXNUnepw'} color={'primary'} target={'_blank'}>Учебный календарный график</Link>
        </Container>
    );
};

const StyledListItem = styled(Typography)({
    marginLeft: 60
})
export default OrganizationInformation;
