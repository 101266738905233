import React, {useContext} from 'react';
import {useLocation, useRoutes} from 'react-router-dom';
import SettingsContext from 'context/settingsContext';
import routes from './routes';
import {ThemeProvider} from '@mui/material';
import createAppTheme from './theme';
import CssBaseline from '@mui/material/CssBaseline';
import {AuthProvider} from 'context/authContext';
import {ToastProvider} from 'context/toastContext';
import 'react-image-crop/dist/ReactCrop.css';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import TabsProvider from 'context/tabsContext';
import {ConfirmModalProvider} from 'context/confirmModalContext';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {TinodeProvider} from 'context/tinodeContext';
import posthog from "posthog-js";

Sentry.init({
    dsn: 'https://7720a619359a458ca670dc9141f41458@sentry.etence.org/2',
    environment: process.env.REACT_APP_NODE_ENV,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
    beforeSend: (event) => {
        if (window.location.hostname === 'localhost') {
            return null;
        }
        return event;
    }
});

export default function App() {
    const content = useRoutes(routes);
    const {settings} = useContext(SettingsContext);
    const theme = createAppTheme({theme: settings.theme});

    //track pageview with posthog
    let location = useLocation();

    React.useEffect(() => {
        posthog.capture('$pageview')
    }, [location]);



    return (
        <LocalizationProvider
            dateAdapter={AdapterMoment}
            localeText={{
                okButtonLabel: 'ок',
                cancelButtonLabel: 'отмена'
            }}>
            <ThemeProvider theme={theme}>
                <TabsProvider>
                    <ToastProvider>
                        <AuthProvider>
                            <TinodeProvider>
                                <ConfirmModalProvider>
                                    <CssBaseline />
                                    {content}
                                </ConfirmModalProvider>
                            </TinodeProvider>
                        </AuthProvider>
                    </ToastProvider>
                </TabsProvider>
            </ThemeProvider>
        </LocalizationProvider>
    );
}
